import "./App.css";
import { useState } from "react";
import { AiOutlineMenu, AiOutlineIdcard } from "react-icons/ai";
import { SiTypescript } from "react-icons/si";
import { FaJsfiddle } from "react-icons/fa";
import { BsFillFileEarmarkBinaryFill } from "react-icons/bs";
import { FiMap } from "react-icons/fi";
import { BiTimeFive } from "react-icons/bi";
import { MdEnhancedEncryption, MdPassword, MdQrCode } from "react-icons/md";
import { Menu, MenuItem, ProSidebar, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import TimeStamp from "./Components/TimeStamp";
import Encrypt from "./Components/Encrypt";
import Base64 from "./Components/Base64";
import UUID from "./Components/UUID";
import JSONView from "./Components/JSON";
import PasswordGenerator from "./Components/PasswordGenerator";
import QRCodeGenerator from "./Components/QRCode/QRCodeGenerator";
import JSONtoTS from "./Components/JSONtoTS";
import GoogleApiHeatMap from "./Components/GoogleHeatMap";

function App() {
  const SideNavigation = () => {
    const [collapsed, setCollapsed] = useState(false);

    // added styles

    const onClickMenuIcon = () => {
      setCollapsed(!collapsed);
    };

    return (
      <ProSidebar style={styles.sideBarHeight} collapsed={collapsed}>
        <SidebarHeader style={{ padding: "15px", paddingLeft: "30px" }}>
          <div style={styles.menuIcon} onClick={onClickMenuIcon}>
            <AiOutlineMenu />
          </div>
        </SidebarHeader>
        <Menu iconShape="square">
          <MenuItem
            icon={<FaJsfiddle />}
            onClick={() => {
              setCurrentlyViewing("json");
            }}
          >
            {" "}
            JSON
          </MenuItem>
          <MenuItem
            icon={<BsFillFileEarmarkBinaryFill />}
            onClick={() => {
              setCurrentlyViewing("base64");
            }}
          >
            Base64
          </MenuItem>
          <MenuItem
            icon={<AiOutlineIdcard />}
            onClick={() => {
              setCurrentlyViewing("uuid");
            }}
          >
            UUID
          </MenuItem>
          <MenuItem
            icon={<BiTimeFive />}
            onClick={() => {
              setCurrentlyViewing("timestamp");
            }}
          >
            TimeStamp
          </MenuItem>
          <MenuItem
            icon={<MdEnhancedEncryption />}
            onClick={() => {
              setCurrentlyViewing("bcrypt");
            }}
          >
            Bcrypt
          </MenuItem>
          <MenuItem
            icon={<MdPassword />}
            onClick={() => {
              setCurrentlyViewing("PasswordGenerator");
            }}
          >
            PasswordGenerator
          </MenuItem>
          <MenuItem
            icon={<MdQrCode />}
            onClick={() => {
              setCurrentlyViewing("QR Code Generator");
            }}
          >
            QR Code Generator
          </MenuItem>
          <MenuItem
            icon={<SiTypescript />}
            onClick={() => {
              setCurrentlyViewing("JSON to TypeScript");
            }}
          >
            JSON to TypeScript
          </MenuItem>
          <MenuItem
            icon={<FiMap />}
            onClick={() => {
              setCurrentlyViewing("GoogleApi HeatMap");
            }}
          >
            Heatmap
          </MenuItem>

{/*           <MenuItem
            icon={<FiMap />}
            onClick={() => {
              setCurrentlyViewing("GoogleHeatMap");
            }}
          >
            Heatmaps
          </MenuItem> */}
          {/* <MenuItem
            icon={<FaFortAwesomeAlt />}
            onClick={() => {
              setCurrentlyViewing("Test UI");
            }}
          >
            Test UI
          </MenuItem> */}
        </Menu>
      </ProSidebar>
    );
  };

  const styles = {
    contentDiv: {
      display: "flex",
    },
    contentMargin: {
      width: "100%",
      padding: 50,
    },
  };
  const [currentlyViewing, setCurrentlyViewing] = useState("");

  return (
    <div className="App">
      <div style={styles.contentDiv}>
        <SideNavigation></SideNavigation>
        <div style={styles.contentMargin}>
          {/*"Show JSON"*/}
          {currentlyViewing === "json" && <JSONView />}
          {/*"Show UUID"*/}
          {currentlyViewing === "uuid" && <UUID />}
          {/*"Show Base64"*/}
          {currentlyViewing === "base64" && <Base64 />}
          {/*"Show timestamp"*/}
          {currentlyViewing === "timestamp" && <TimeStamp />}
          {/*"Show Bcrypt"*/}
          {currentlyViewing === "bcrypt" && <Encrypt />}
          {/*"Show PasswordGenerator"*/}
          {currentlyViewing === "PasswordGenerator" && <PasswordGenerator />}
          {/*"Show QR Code Generator"*/}
          {currentlyViewing === "QR Code Generator" && <QRCodeGenerator />}
          {/*"Show Test UI Page"*/}
          {currentlyViewing === "JSON to TypeScript" && <JSONtoTS />}
          {/*"Show Test UI Page"*/}
          {currentlyViewing === "GoogleApi HeatMap" && <GoogleApiHeatMap />}
          {/*"Show Test UI Page"*/}
          {/* {currentlyViewing === "Test UI" && <TestUI />} */}
        </div>
      </div>
    </div>
  );
}

export default App;
