import QRCode from "qrcode";
import React, { useState, useEffect } from "react";
import "../../App.css";
import { useDropzone } from "react-dropzone";
import SingleQRCode from "./SingleQRCode";
import { RiDragDropLine } from "react-icons/ri";
import { BsClipboardFill } from "react-icons/bs";
function QRCodeGenerator() {
  const [text, SetText] = useState("Content in Default QR Code");
  const current = new Date();
  const nameCode = "QR Code ";
  const time = `${current.getTime()}`;
  const [src, SetSRC] = useState("");
  const [files, Setfiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const preparedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      Setfiles(preparedFiles);
    },
  });

  useEffect(() => {
    QRCode.toDataURL(text).then(SetSRC);
  }, []);

  return (
    <div style={{ paddingLeft: "0px" }} className="col-md-10 text-left">
      <div {...getRootProps()} className="divdrop">
        <div {...getInputProps()} />
        <div>
          <RiDragDropLine size={100} />
          <h1>{"Drag & Drop QR Codes Here"}</h1>
          <h1>{"You can drop mulitple files"}</h1>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <h3 style={{ margin: "auto" }}>QR Code Generator</h3>
      </div>
      <div style={{ display: "flex", gap: 20 }}>
        <img src={src} style={{ width: "200px" }} alt="preview" />
        <div
          style={{
            margin: "auto",
          }}
          class="input-group col-sm-7"
        >
          <input
            type="text"
            className="form-control "
            value={text}
            onChange={(e) => {
              SetText(e.target.value);
            }}
            placeholder="Enter text here to generate"
          />
          <div class="input-group-append">
            <button
              className="btn btn-light "
              onClick={async () => {
                let clipboard = navigator.clipboard
                  .readText()
                  .then((clipBoardText) => {
                    console.log(clipBoardText);
                    SetText(clipBoardText);
                  });
              }}
            >
              <BsClipboardFill />
            </button>
          </div>
        </div>
        <div style={{ margin: "auto" }}>
          <button
            className="form-control "
            onClick={(e) => {
              QRCode.toDataURL(text).then(SetSRC);
            }}
          >
            Generate
          </button>
        </div>
        <div style={{ margin: "auto" }}>
          <a href={src} download={nameCode + time}>
            <button className="form-control ">Download</button>
          </a>
        </div>
      </div>
      <div style={{ paddingTop: 10 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <h3 style={{ margin: "auto" }}>QR Code content will be below Here</h3>
          {files &&
            files.map((file) => <SingleQRCode filePreview={file.preview} />)}
        </div>
      </div>
    </div>
  );
}
export default QRCodeGenerator;
