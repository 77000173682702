import "./style.css";
import React from "react";
import {
  GoogleMap,
  useLoadScript,
  HeatmapLayerF,
} from "@react-google-maps/api";


const center = { lat: 51.1657, lng: 10.4515 };
function GoogleHeatMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["visualization"],
  });

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  const heatmapData = [
    new window.google.maps.LatLng(51.145235, 13.7997102),
    new window.google.maps.LatLng(51.0940541, 13.7200896),
    new window.google.maps.LatLng(51.092268, 13.7214136),
    new window.google.maps.LatLng(51.0368213, 13.7795544),
    new window.google.maps.LatLng(51.0634874, 13.82187),
    new window.google.maps.LatLng(51.0617409, 13.8853194),
    new window.google.maps.LatLng(51.02932999999999, 14.20416),
    new window.google.maps.LatLng(50.9892058, 13.9972488),
    new window.google.maps.LatLng(51.4587417, 13.8661956),
    new window.google.maps.LatLng(51.1792649, 14.4177194),
    new window.google.maps.LatLng(50.89644269999999, 14.8065112),
    new window.google.maps.LatLng(50.9448052, 14.8890161),
    new window.google.maps.LatLng(51.1435705, 14.9729054),
    new window.google.maps.LatLng(51.5064869, 14.6401291),
    new window.google.maps.LatLng(51.3849943, 14.2447017),
    new window.google.maps.LatLng(51.7743244, 14.3431196),
    new window.google.maps.LatLng(51.7861665, 14.3422985),
    new window.google.maps.LatLng(51.5717412, 14.3787142),
    new window.google.maps.LatLng(51.7446791, 14.6432601),
    new window.google.maps.LatLng(51.9518316, 14.7146382),
    new window.google.maps.LatLng(51.857838, 14.4124336),
    new window.google.maps.LatLng(51.86597889999999, 13.956404),
    new window.google.maps.LatLng(51.6378348, 13.5627012),
    new window.google.maps.LatLng(51.3375387, 12.3275348),
    new window.google.maps.LatLng(51.3470509, 12.2660889),
    new window.google.maps.LatLng(51.3436212, 12.3206886),
    new window.google.maps.LatLng(51.3303708, 12.3160573),
    new window.google.maps.LatLng(51.3277378, 12.3409677),
    new window.google.maps.LatLng(51.3532565, 12.4634442),
    new window.google.maps.LatLng(51.3580323, 12.4580055),
    new window.google.maps.LatLng(51.3822243, 12.4515738),
    new window.google.maps.LatLng(51.2840061, 12.4283494),
    new window.google.maps.LatLng(51.2175038, 12.3244993),
    new window.google.maps.LatLng(50.896305, 12.3524263),
    new window.google.maps.LatLng(51.05510229999999, 12.5557092),
    new window.google.maps.LatLng(51.0937109, 12.9761443),
    new window.google.maps.LatLng(51.28323, 12.9380299),
    new window.google.maps.LatLng(51.5814124, 13.2383601),
    new window.google.maps.LatLng(51.692465, 13.2341394),
    new window.google.maps.LatLng(51.5248009, 13.4042701),
    new window.google.maps.LatLng(51.48718830000001, 11.9756302),
    new window.google.maps.LatLng(51.4956221, 11.965538),
    new window.google.maps.LatLng(51.35471, 11.97614),
    new window.google.maps.LatLng(51.2971764, 12.0682686),
    new window.google.maps.LatLng(51.3734034, 11.602775),
    new window.google.maps.LatLng(51.5568999, 11.4892256),
    new window.google.maps.LatLng(51.7552495, 12.0012616),
    new window.google.maps.LatLng(51.75136699999999, 11.9812901),
    new window.google.maps.LatLng(51.7992014, 12.0152115),
    new window.google.maps.LatLng(51.7502179, 12.1270202),
    new window.google.maps.LatLng(51.73181, 11.3452201),
    new window.google.maps.LatLng(51.79087999999999, 11.14305),
    new window.google.maps.LatLng(51.72183039999999, 11.2265716),
    new window.google.maps.LatLng(51.7209037, 11.2381111),
    new window.google.maps.LatLng(51.75592, 11.0418),
    new window.google.maps.LatLng(51.46162, 11.29411),
    new window.google.maps.LatLng(51.47905369999999, 11.2945612),
    new window.google.maps.LatLng(51.4754283, 11.2970041),
    new window.google.maps.LatLng(51.3652155, 11.2911605),
    new window.google.maps.LatLng(51.29562, 11.43385),
    new window.google.maps.LatLng(51.26668, 11.40938),
    new window.google.maps.LatLng(51.29867609999999, 11.4331718),
    new window.google.maps.LatLng(51.1194837, 12.0213777),
    new window.google.maps.LatLng(51.0445359, 12.1555612),
    new window.google.maps.LatLng(51.0504761, 12.1362352),
    new window.google.maps.LatLng(51.0814337, 12.1103548),
    new window.google.maps.LatLng(51.05744, 12.17965),
    new window.google.maps.LatLng(51.62392999999999, 12.32912),
    new window.google.maps.LatLng(51.6221428, 12.3308267),
    new window.google.maps.LatLng(51.6624341, 12.2740591),
    new window.google.maps.LatLng(51.6294036, 12.2647),
    new window.google.maps.LatLng(50.400336, 11.6960497),
    new window.google.maps.LatLng(50.7206094, 11.3405083),
    new window.google.maps.LatLng(50.7589547, 11.2279641),
    new window.google.maps.LatLng(50.88054959999999, 12.0776766),
    new window.google.maps.LatLng(50.91992399999999, 12.0523604),
    new window.google.maps.LatLng(50.8222069, 11.9297355),
    new window.google.maps.LatLng(50.9678964, 11.9057003),
    new window.google.maps.LatLng(50.88117090000001, 11.6037204),
    new window.google.maps.LatLng(50.64815340000001, 11.9771088),
    new window.google.maps.LatLng(50.6658498, 12.215315),
    new window.google.maps.LatLng(50.7215559, 12.4955089),
    new window.google.maps.LatLng(50.6858173, 12.4699595),
    new window.google.maps.LatLng(50.5861482, 12.7017223),
    new window.google.maps.LatLng(50.7285044, 12.3745581),
    new window.google.maps.LatLng(50.81215719999999, 12.3868605),
    new window.google.maps.LatLng(50.6241218, 12.2987322),
    new window.google.maps.LatLng(50.62124, 12.30284),
    new window.google.maps.LatLng(50.4153943, 12.1728404),
    new window.google.maps.LatLng(50.8478708, 12.8926226),
    new window.google.maps.LatLng(50.84018810000001, 12.9004869),
    new window.google.maps.LatLng(50.8223745, 12.9596154),
    new window.google.maps.LatLng(50.8359, 12.9401328),
    new window.google.maps.LatLng(50.8589482, 12.7699306),
    new window.google.maps.LatLng(50.87896629999999, 12.8703028),
    new window.google.maps.LatLng(50.9078064, 13.0521031),
    new window.google.maps.LatLng(52.500756, 13.3304612),
    new window.google.maps.LatLng(52.49524659999999, 13.340858),
    new window.google.maps.LatLng(52.4773691, 13.4229516),
    new window.google.maps.LatLng(52.4746561, 13.3659682),
    new window.google.maps.LatLng(52.44794049999999, 13.3849574),
    new window.google.maps.LatLng(52.4426144, 13.4569825),
    new window.google.maps.LatLng(52.4589351, 13.5257704),
    new window.google.maps.LatLng(52.439706, 13.5002113),
    new window.google.maps.LatLng(52.4124697, 13.5721657),
    new window.google.maps.LatLng(52.44134589999999, 13.6889472),
    new window.google.maps.LatLng(52.5093101, 13.6139288),
    new window.google.maps.LatLng(52.5050345, 13.5576507),
    new window.google.maps.LatLng(52.57092, 13.41079),
    new window.google.maps.LatLng(52.6097601, 13.3295342),
    new window.google.maps.LatLng(52.5193752, 13.2570096),
    new window.google.maps.LatLng(52.39365309999999, 13.2120881),
    new window.google.maps.LatLng(52.60679640000001, 12.8775561),
    new window.google.maps.LatLng(51.9909936, 13.0755576),
    new window.google.maps.LatLng(52.5969445, 14.2365579),
    new window.google.maps.LatLng(52.54947, 14.55155),
    new window.google.maps.LatLng(52.53732, 13.63356),
    new window.google.maps.LatLng(52.1445826, 14.6720858),
    new window.google.maps.LatLng(51.9414119, 13.8798738),
    new window.google.maps.LatLng(51.94599299999999, 13.8768052),
    new window.google.maps.LatLng(52.78299680000001, 14.0329063),
    new window.google.maps.LatLng(52.68237, 13.585986),
    new window.google.maps.LatLng(52.7656866, 13.6318984),
    new window.google.maps.LatLng(52.7673371, 13.6175485),
    new window.google.maps.LatLng(52.6701483, 13.2834978),
    new window.google.maps.LatLng(52.67369799999999, 13.316764),
    new window.google.maps.LatLng(52.66328, 13.37863),
    new window.google.maps.LatLng(52.72367, 13.19088),
    new window.google.maps.LatLng(52.91533, 12.78992),
    new window.google.maps.LatLng(52.91937840000001, 12.7964468),
    new window.google.maps.LatLng(52.9214364, 12.8011189),
    new window.google.maps.LatLng(52.9257575, 12.8068863),
    new window.google.maps.LatLng(53.1541776, 12.1711301),
    new window.google.maps.LatLng(53.5768464, 13.2811327),
    new window.google.maps.LatLng(53.9047639, 13.0423067),
    new window.google.maps.LatLng(53.90655, 13.0407799),
    new window.google.maps.LatLng(53.8227631, 12.791513),
    new window.google.maps.LatLng(53.7736844, 12.5750742),
    new window.google.maps.LatLng(53.5142587, 12.6867424),
    new window.google.maps.LatLng(53.1170151, 13.5062918),
    new window.google.maps.LatLng(53.4592728, 13.5841587),
    new window.google.maps.LatLng(53.97166319999999, 14.1438899),
    new window.google.maps.LatLng(54.0893783, 12.0974753),
    new window.google.maps.LatLng(54.0857732, 12.1186842),
    new window.google.maps.LatLng(54.1385156, 12.0552854),
    new window.google.maps.LatLng(54.31501489999999, 13.3528734),
    new window.google.maps.LatLng(54.3996321, 13.6114415),
    new window.google.maps.LatLng(53.62822999999999, 11.4105726),
    new window.google.maps.LatLng(53.6284797, 11.412174),
    new window.google.maps.LatLng(53.6483708, 11.3704625),
    new window.google.maps.LatLng(53.7759112, 11.0513472),
    new window.google.maps.LatLng(53.329898, 11.4964478),
    new window.google.maps.LatLng(53.0738516, 11.8666459),
    new window.google.maps.LatLng(53.6593812, 11.9016151),
    new window.google.maps.LatLng(53.5154706, 9.793075),
    new window.google.maps.LatLng(53.2023573, 10.4386887),
    new window.google.maps.LatLng(53.43160280000001, 10.3758336),
    new window.google.maps.LatLng(53.5418344, 10.2138977),
    new window.google.maps.LatLng(53.54236270000001, 10.2137618),
    new window.google.maps.LatLng(53.47305, 9.6942001),
    new window.google.maps.LatLng(53.4749369, 9.6973266),
    new window.google.maps.LatLng(53.5537036, 10.0845825),
    new window.google.maps.LatLng(53.6160657, 10.1134004),
    new window.google.maps.LatLng(53.5909601, 10.0443227),
    new window.google.maps.LatLng(53.5595942, 9.8813719),
    new window.google.maps.LatLng(53.5518744, 9.9293227),
    new window.google.maps.LatLng(53.9667167, 11.1651568),
    new window.google.maps.LatLng(53.8968775, 11.4437145),
    new window.google.maps.LatLng(53.9024242, 11.3789469),
    new window.google.maps.LatLng(54.3289862, 10.1978986),
    new window.google.maps.LatLng(54.46919519999999, 9.837145),
    new window.google.maps.LatLng(54.4677148, 9.8362392),
    new window.google.maps.LatLng(53.1246508, 8.1913086),
    new window.google.maps.LatLng(53.1926479, 7.9860264),
    new window.google.maps.LatLng(52.97525, 7.7714),
    new window.google.maps.LatLng(53.29075640000001, 8.1632515),
    new window.google.maps.LatLng(53.2489457, 8.197575),
    new window.google.maps.LatLng(53.06372, 8.19572),
    new window.google.maps.LatLng(53.4032136, 8.1129237),
    new window.google.maps.LatLng(53.5204094, 8.110878),
    new window.google.maps.LatLng(53.2577296, 7.9233892),
    new window.google.maps.LatLng(53.168837, 7.744274),
    new window.google.maps.LatLng(53.2186084, 7.8071479),
    new window.google.maps.LatLng(53.4350671, 7.0970252),
    new window.google.maps.LatLng(53.2285919, 7.4507217),
    new window.google.maps.LatLng(53.18225, 7.267045),
    new window.google.maps.LatLng(53.3264969, 8.4789928),
    new window.google.maps.LatLng(52.9093436, 8.5870953),
    new window.google.maps.LatLng(52.9236342, 9.228476),
    new window.google.maps.LatLng(53.168658, 9.4863148),
    new window.google.maps.LatLng(53.5885911, 8.6162944),
    new window.google.maps.LatLng(53.5932312, 8.6178522),
    new window.google.maps.LatLng(53.4724848, 8.6517924),
    new window.google.maps.LatLng(53.0835, 8.57718),
    new window.google.maps.LatLng(53.0740877, 8.622136),
    new window.google.maps.LatLng(53.0335556, 8.8329553),
    new window.google.maps.LatLng(53.07971999999999, 8.89278),
    new window.google.maps.LatLng(52.9838233, 8.8335789),
    new window.google.maps.LatLng(52.52173999999999, 10.21494),
    new window.google.maps.LatLng(52.9603253, 10.5685293),
    new window.google.maps.LatLng(52.37372999999999, 9.73772),
    new window.google.maps.LatLng(52.3747745, 9.7391901),
    new window.google.maps.LatLng(52.3756113, 9.7347963),
    new window.google.maps.LatLng(52.3330305, 9.690208),
    new window.google.maps.LatLng(52.3406153, 9.6931244),
    new window.google.maps.LatLng(52.40217, 9.79324),
    new window.google.maps.LatLng(52.4094907, 9.8014381),
    new window.google.maps.LatLng(52.302012, 9.4647527),
    new window.google.maps.LatLng(52.54701009999999, 9.7320293),
    new window.google.maps.LatLng(52.31538219999999, 9.7228615),
    new window.google.maps.LatLng(52.15333, 9.95017),
    new window.google.maps.LatLng(52.1530567, 9.9565307),
    new window.google.maps.LatLng(52.1626594, 9.9173868),
    new window.google.maps.LatLng(52.1338574, 9.9435491),
    new window.google.maps.LatLng(52.3830118, 10.2586706),
    new window.google.maps.LatLng(52.4471879, 10.010016),
    new window.google.maps.LatLng(52.56179059999999, 9.1242526),
    new window.google.maps.LatLng(51.98503239999999, 9.2602453),
    new window.google.maps.LatLng(52.1103258, 8.7029378),
    new window.google.maps.LatLng(52.0701249, 8.7524453),
    new window.google.maps.LatLng(52.1381898, 8.558066),
    new window.google.maps.LatLng(52.2010561, 8.5214203),
    new window.google.maps.LatLng(52.20407669999999, 8.5672047),
    new window.google.maps.LatLng(52.1985969, 8.5855124),
    new window.google.maps.LatLng(52.2418328, 8.6282167),
    new window.google.maps.LatLng(52.3021533, 8.9076073),
    new window.google.maps.LatLng(52.1064336, 8.9477347),
    new window.google.maps.LatLng(51.93546139999999, 8.8800216),
    new window.google.maps.LatLng(51.9417286, 8.8784615),
    new window.google.maps.LatLng(51.8718706, 8.9410342),
    new window.google.maps.LatLng(51.7341782, 9.0213504),
    new window.google.maps.LatLng(51.7143299, 8.7422354),
    new window.google.maps.LatLng(51.7833504, 8.8166088),
    new window.google.maps.LatLng(51.8378312, 8.3140049),
    new window.google.maps.LatLng(51.776061, 8.3188913),
    new window.google.maps.LatLng(52.0294654, 8.5395055),
    new window.google.maps.LatLng(51.9878708, 8.504953),
    new window.google.maps.LatLng(51.9473335, 8.5875401),
    new window.google.maps.LatLng(52.0966442, 8.5174886),
    new window.google.maps.LatLng(52.07325600000001, 8.41353),
    new window.google.maps.LatLng(51.3158756, 9.4658421),
    new window.google.maps.LatLng(51.29127099999999, 9.513839),
    new window.google.maps.LatLng(51.325819, 9.4766528),
    new window.google.maps.LatLng(51.2564297, 9.4179474),
    new window.google.maps.LatLng(51.3455879, 9.4595383),
    new window.google.maps.LatLng(51.17779100000001, 9.3689019),
    new window.google.maps.LatLng(51.211765, 9.4452928),
    new window.google.maps.LatLng(51.4156571, 9.6531832),
    new window.google.maps.LatLng(51.1331534, 9.2746895),
    new window.google.maps.LatLng(51.0311423, 9.420499),
    new window.google.maps.LatLng(50.7688066, 8.5804933),
    new window.google.maps.LatLng(50.729886, 8.8553378),
    new window.google.maps.LatLng(50.7615328, 8.8805619),
    new window.google.maps.LatLng(51.0126074, 8.6427219),
    new window.google.maps.LatLng(50.95691009999999, 8.7154123),
    new window.google.maps.LatLng(50.9016469, 8.5345034),
    new window.google.maps.LatLng(50.8509265, 9.1193936),
    new window.google.maps.LatLng(50.5826348, 8.6798275),
    new window.google.maps.LatLng(50.6051461, 8.7048416),
    new window.google.maps.LatLng(50.6841994, 8.3065103),
    new window.google.maps.LatLng(50.6532232, 8.3290516),
    new window.google.maps.LatLng(50.6727675, 9.7666468),
    new window.google.maps.LatLng(50.6718765, 9.7678525),
    new window.google.maps.LatLng(50.9697567, 9.7925094),
    new window.google.maps.LatLng(50.6254, 9.50553),
    new window.google.maps.LatLng(50.3480307, 9.5286394),
    new window.google.maps.LatLng(51.5307701, 9.936225),
    new window.google.maps.LatLng(51.4674764, 9.9876004),
    new window.google.maps.LatLng(51.6549318, 10.1250869),
    new window.google.maps.LatLng(51.66222759999999, 10.1744846),
    new window.google.maps.LatLng(51.1920808, 10.037947),
    new window.google.maps.LatLng(51.2111423, 9.9383295),
    new window.google.maps.LatLng(51.6215994, 10.4522898),
    new window.google.maps.LatLng(51.6313902, 10.4711002),
    new window.google.maps.LatLng(51.58455, 10.29699),
    new window.google.maps.LatLng(51.59549029999999, 10.5541498),
    new window.google.maps.LatLng(51.8184218, 9.8674023),
    new window.google.maps.LatLng(52.26479, 10.52662),
    new window.google.maps.LatLng(52.2671512, 10.5408816),
    new window.google.maps.LatLng(52.23372999999999, 10.55799),
    new window.google.maps.LatLng(52.1603279, 10.5339663),
    new window.google.maps.LatLng(52.62746000000001, 11.16021),
    new window.google.maps.LatLng(52.6270525, 11.1595845),
    new window.google.maps.LatLng(51.9457852, 10.8247624),
    new window.google.maps.LatLng(52.09883, 11.57476),
    new window.google.maps.LatLng(51.9033153, 11.7755694),
    new window.google.maps.LatLng(51.84793999999999, 11.53604),
    new window.google.maps.LatLng(51.2364215, 6.7787067),
    new window.google.maps.LatLng(51.2312986, 6.7501344),
    new window.google.maps.LatLng(51.2132611, 6.9626186),
    new window.google.maps.LatLng(51.1191707, 6.8854233),
    new window.google.maps.LatLng(51.1960493, 6.4423042),
    new window.google.maps.LatLng(51.1846662, 6.440392),
    new window.google.maps.LatLng(51.0990015, 6.5082948),
    new window.google.maps.LatLng(51.1568799, 6.7308841),
    new window.google.maps.LatLng(51.2126137, 6.6833647),
    new window.google.maps.LatLng(51.0927814, 6.8425536),
    new window.google.maps.LatLng(51.12260999999999, 6.84264),
    new window.google.maps.LatLng(51.25113940000001, 6.3333643),
    new window.google.maps.LatLng(51.2513474, 6.3322568),
    new window.google.maps.LatLng(51.1394942, 6.2819),
    new window.google.maps.LatLng(51.2564054, 7.1489532),
    new window.google.maps.LatLng(51.23114469999999, 7.1479901),
    new window.google.maps.LatLng(51.28100389999999, 7.0357815),
    new window.google.maps.LatLng(51.33875, 7.04725),
    new window.google.maps.LatLng(51.51541, 7.50313),
    new window.google.maps.LatLng(51.4778008, 7.5353067),
    new window.google.maps.LatLng(51.4776236, 7.5974322),
    new window.google.maps.LatLng(51.5236743, 7.5487264),
    new window.google.maps.LatLng(51.54635, 7.37724),
    new window.google.maps.LatLng(51.53950560000001, 7.2556813),
    new window.google.maps.LatLng(51.4788722, 7.2036904),
    new window.google.maps.LatLng(51.5193811, 7.2777328),
    new window.google.maps.LatLng(51.5034414, 7.2707794),
    new window.google.maps.LatLng(51.481966, 7.1421906),
    new window.google.maps.LatLng(51.49600299999999, 7.1478564),
    new window.google.maps.LatLng(51.47110499999999, 7.314677),
    new window.google.maps.LatLng(51.4656228, 7.0958418),
    new window.google.maps.LatLng(51.4729082, 6.9497209),
    new window.google.maps.LatLng(51.5779104, 7.1997892),
    new window.google.maps.LatLng(51.715749, 7.0946456),
    new window.google.maps.LatLng(51.7432458, 7.1911022),
    new window.google.maps.LatLng(51.5102936, 7.0932703),
    new window.google.maps.LatLng(51.5358532, 6.9723178),
    new window.google.maps.LatLng(51.6589354, 6.9597664),
    new window.google.maps.LatLng(51.43309900000001, 6.7636283),
    new window.google.maps.LatLng(51.4672589, 6.7701042),
    new window.google.maps.LatLng(51.3971926, 6.6612106),
    new window.google.maps.LatLng(51.4386099, 6.5319676),
    new window.google.maps.LatLng(51.9541747, 7.6264157),
    new window.google.maps.LatLng(51.9944546, 7.6021752),
    new window.google.maps.LatLng(51.86467390000001, 7.3679268),
    new window.google.maps.LatLng(51.9908636, 8.0398983),
    new window.google.maps.LatLng(51.9916404, 8.0413178),
    new window.google.maps.LatLng(52.0485522, 8.0311718),
    new window.google.maps.LatLng(52.2728583, 7.5825429),
    new window.google.maps.LatLng(52.433749, 7.0684512),
    new window.google.maps.LatLng(52.2114493, 7.0228946),
    new window.google.maps.LatLng(52.07343729999999, 6.9185259),
    new window.google.maps.LatLng(52.2735901, 8.0495049),
    new window.google.maps.LatLng(52.3199029, 8.3415199),
    new window.google.maps.LatLng(52.5210993, 8.1919563),
    new window.google.maps.LatLng(52.4698633, 8.375577),
    new window.google.maps.LatLng(52.4085652, 7.9814036),
    new window.google.maps.LatLng(52.4085988, 7.9790762),
    new window.google.maps.LatLng(52.5541327, 7.9483515),
    new window.google.maps.LatLng(52.7336618, 7.7560764),
    new window.google.maps.LatLng(52.7198113, 7.9392687),
    new window.google.maps.LatLng(52.8368, 7.10169),
    new window.google.maps.LatLng(52.51907, 7.1323901),
    new window.google.maps.LatLng(50.95404130000001, 6.6415473),
    new window.google.maps.LatLng(50.92787999999999, 6.5841598),
    new window.google.maps.LatLng(50.90974, 6.81138),
    new window.google.maps.LatLng(50.9228512, 6.8026912),
    new window.google.maps.LatLng(50.98095, 6.78657),
    new window.google.maps.LatLng(50.9540315, 6.9571813),
    new window.google.maps.LatLng(51.037558, 6.8834945),
    new window.google.maps.LatLng(50.9497964, 6.9169934),
    new window.google.maps.LatLng(50.876271, 6.9591019),
    new window.google.maps.LatLng(50.94533999999999, 7.07539),
    new window.google.maps.LatLng(50.8817162, 7.0814362),
    new window.google.maps.LatLng(50.8956492, 7.0633662),
    new window.google.maps.LatLng(50.9153473, 7.6556136),
    new window.google.maps.LatLng(51.03333, 7.6423),
    new window.google.maps.LatLng(50.9753598, 7.367508),
    new window.google.maps.LatLng(50.7757996, 6.0960131),
    new window.google.maps.LatLng(50.7793297, 6.0997034),
    new window.google.maps.LatLng(50.7326162, 7.1012525),
    new window.google.maps.LatLng(50.6848953, 7.1541678),
    new window.google.maps.LatLng(50.7622475, 6.9907107),
    new window.google.maps.LatLng(50.543742, 7.2495348),
    new window.google.maps.LatLng(50.63177280000001, 7.2259567),
    new window.google.maps.LatLng(50.6744368, 7.1937487),
    new window.google.maps.LatLng(50.871579, 7.3255441),
    new window.google.maps.LatLng(50.8134003, 7.161097),
    new window.google.maps.LatLng(50.66462, 6.77409),
    new window.google.maps.LatLng(49.85729, 6.89668),
    new window.google.maps.LatLng(49.75349869999999, 6.9973553),
    new window.google.maps.LatLng(49.9848234, 6.8871729),
    new window.google.maps.LatLng(50.04187220000001, 6.5898173),
    new window.google.maps.LatLng(49.9451129, 6.3330033),
    new window.google.maps.LatLng(49.99961, 8.26783),
    new window.google.maps.LatLng(49.9624422, 8.2802588),
    new window.google.maps.LatLng(49.9999905, 8.3055906),
    new window.google.maps.LatLng(49.9076434, 8.2014219),
    new window.google.maps.LatLng(49.8759576, 8.1502084),
    new window.google.maps.LatLng(49.9008008, 7.9225213),
    new window.google.maps.LatLng(49.8606115, 7.9863541),
    new window.google.maps.LatLng(50.3570997, 7.5938705),
    new window.google.maps.LatLng(50.3583071, 7.5919347),
    new window.google.maps.LatLng(50.3461779, 7.5891187),
    new window.google.maps.LatLng(50.3813323, 7.5778125),
    new window.google.maps.LatLng(50.3704701, 7.5194013),
    new window.google.maps.LatLng(50.32818, 7.72862),
    new window.google.maps.LatLng(50.2801595, 7.5472603),
    new window.google.maps.LatLng(50.4196706, 7.9353741),
    new window.google.maps.LatLng(50.4829431, 7.9553248),
    new window.google.maps.LatLng(50.4403235, 7.4727491),
    new window.google.maps.LatLng(50.4256048, 7.5450339),
    new window.google.maps.LatLng(50.3291744, 7.2190395),
    new window.google.maps.LatLng(50.32948, 7.2233399),
    new window.google.maps.LatLng(50.3709248, 7.2828492),
    new window.google.maps.LatLng(50.3005672, 7.3113086),
    new window.google.maps.LatLng(50.8575084, 8.0099203),
    new window.google.maps.LatLng(50.8443465, 7.9684173),
    new window.google.maps.LatLng(51.1249885, 7.9038998),
    new window.google.maps.LatLng(51.44459879999999, 7.568324),
    new window.google.maps.LatLng(51.4523654, 7.3254908),
    new window.google.maps.LatLng(51.21610339999999, 7.6298473),
    new window.google.maps.LatLng(51.2128925, 7.6375658),
    new window.google.maps.LatLng(51.3300892, 7.8643157),
    new window.google.maps.LatLng(51.6747206, 7.823506),
    new window.google.maps.LatLng(51.705098, 7.833542),
    new window.google.maps.LatLng(51.5333528, 7.6889927),
    new window.google.maps.LatLng(51.50442349999999, 7.9618858),
    new window.google.maps.LatLng(51.5707247, 8.1076163),
    new window.google.maps.LatLng(51.44835519999999, 7.9674998),
    new window.google.maps.LatLng(51.39460750000001, 8.0631875),
    new window.google.maps.LatLng(51.3973594, 8.0711251),
    new window.google.maps.LatLng(51.3189039, 8.0075393),
    new window.google.maps.LatLng(50.11382, 8.6773001),
    new window.google.maps.LatLng(50.1340972, 8.6710798),
    new window.google.maps.LatLng(50.1074463, 8.6641954),
    new window.google.maps.LatLng(50.1552454, 8.7510573),
    new window.google.maps.LatLng(50.2041312, 8.7081938),
    new window.google.maps.LatLng(50.1747589, 8.6315359),
    new window.google.maps.LatLng(50.0947429, 8.6820148),
    new window.google.maps.LatLng(50.1791378, 8.7360219),
    new window.google.maps.LatLng(50.2361784, 8.8639969),
    new window.google.maps.LatLng(50.2420032, 8.7188548),
    new window.google.maps.LatLng(50.2265336, 8.5765645),
    new window.google.maps.LatLng(50.17283, 8.52876),
    new window.google.maps.LatLng(50.0263393, 8.8837987),
    new window.google.maps.LatLng(50.0791251, 8.8590787),
    new window.google.maps.LatLng(49.9922259, 8.6487532),
    new window.google.maps.LatLng(49.9911974, 8.660859),
    new window.google.maps.LatLng(50.0311637, 8.7036509),
    new window.google.maps.LatLng(49.97613, 8.82863),
    new window.google.maps.LatLng(50.1330497, 8.9159845),
    new window.google.maps.LatLng(50.1400259, 8.9040315),
    new window.google.maps.LatLng(50.1765363, 8.8891592),
    new window.google.maps.LatLng(50.10726890000001, 8.9135117),
    new window.google.maps.LatLng(50.11255, 8.91368),
    new window.google.maps.LatLng(50.10356489999999, 8.9192721),
    new window.google.maps.LatLng(50.2007523, 9.1201424),
    new window.google.maps.LatLng(49.9740289, 9.1541268),
    new window.google.maps.LatLng(49.976823, 9.154127),
    new window.google.maps.LatLng(49.974098, 9.150633),
    new window.google.maps.LatLng(50.1017, 9.10256),
    new window.google.maps.LatLng(49.9193486, 9.077443),
    new window.google.maps.LatLng(49.8944325, 9.0466578),
    new window.google.maps.LatLng(49.9746059, 9.2977294),
    new window.google.maps.LatLng(49.8634975, 8.6469837),
    new window.google.maps.LatLng(49.8133455, 8.6465589),
    new window.google.maps.LatLng(49.8303745, 8.7459693),
    new window.google.maps.LatLng(50.0122763, 8.5859061),
    new window.google.maps.LatLng(49.90175, 8.51427),
    new window.google.maps.LatLng(49.64901, 8.77902),
    new window.google.maps.LatLng(49.651049, 8.7816256),
    new window.google.maps.LatLng(49.6344439, 8.8348419),
    new window.google.maps.LatLng(49.6590293, 8.9925742),
    new window.google.maps.LatLng(49.56779148550036, 8.97476252427228),
    new window.google.maps.LatLng(50.078512, 8.244842),
    new window.google.maps.LatLng(50.0793419, 8.2053433),
    new window.google.maps.LatLng(50.0432326, 8.2415848),
    new window.google.maps.LatLng(50.1561455, 8.1856952),
    new window.google.maps.LatLng(50.0121394, 8.3512644),
    new window.google.maps.LatLng(50.1409345, 8.0708583),
    new window.google.maps.LatLng(50.2129538, 7.9956291),
    new window.google.maps.LatLng(49.9978753, 8.0067377),
    new window.google.maps.LatLng(50.0448905, 7.8033436),
    new window.google.maps.LatLng(50.04040180000001, 8.0835111),
    new window.google.maps.LatLng(49.9854, 8.42218),
    new window.google.maps.LatLng(49.9896203, 8.3581631),
    new window.google.maps.LatLng(50.2175202, 8.2585708),
    new window.google.maps.LatLng(50.1603123, 8.3140681),
    new window.google.maps.LatLng(50.1469008, 8.3803628),
    new window.google.maps.LatLng(50.12104, 8.56725),
    new window.google.maps.LatLng(49.2197203, 7.0102386),
    new window.google.maps.LatLng(49.23295, 7.04286),
    new window.google.maps.LatLng(49.2320493, 7.021027),
    new window.google.maps.LatLng(49.2836207, 7.1621407),
    new window.google.maps.LatLng(49.4669903, 7.1665458),
    new window.google.maps.LatLng(49.5529154, 7.2491987),
    new window.google.maps.LatLng(49.33146290000001, 6.7482737),
    new window.google.maps.LatLng(49.4085189, 6.9092216),
    new window.google.maps.LatLng(49.37442000000001, 7.63018),
    new window.google.maps.LatLng(49.3847127, 7.4514265),
    new window.google.maps.LatLng(49.1714066, 7.6571311),
    new window.google.maps.LatLng(49.2622159, 7.5789669),
    new window.google.maps.LatLng(49.4622, 8.40633),
    new window.google.maps.LatLng(49.51979009999999, 8.4061926),
    new window.google.maps.LatLng(49.4865356, 8.3491822),
    new window.google.maps.LatLng(49.5320432, 8.3540816),
    new window.google.maps.LatLng(49.5762228, 8.3595634),
    new window.google.maps.LatLng(49.59329, 8.0498943),
    new window.google.maps.LatLng(49.3172844, 8.4345286),
    new window.google.maps.LatLng(49.31718960000001, 8.436695),
    new window.google.maps.LatLng(49.3393039, 8.1617391),
    new window.google.maps.LatLng(49.3536848, 8.1493263),
    new window.google.maps.LatLng(49.3583819, 8.1573998),
    new window.google.maps.LatLng(49.6405633, 8.3638323),
    new window.google.maps.LatLng(49.633322, 8.3596),
    new window.google.maps.LatLng(49.6106651, 8.3218032),
    new window.google.maps.LatLng(49.2874973, 7.6519796),
    new window.google.maps.LatLng(49.57089999999999, 7.85411),
    new window.google.maps.LatLng(49.4881825, 8.4661603),
    new window.google.maps.LatLng(49.4783741, 8.506461),
    new window.google.maps.LatLng(49.44226339999999, 8.5785786),
    new window.google.maps.LatLng(49.4871479, 8.5277266),
    new window.google.maps.LatLng(49.537474, 8.5791579),
    new window.google.maps.LatLng(49.4716961, 8.6049653),
    new window.google.maps.LatLng(49.4476096, 8.6126635),
    new window.google.maps.LatLng(49.5076996, 8.604005),
    new window.google.maps.LatLng(49.471265, 8.5668896),
    new window.google.maps.LatLng(49.22561, 8.51344),
    new window.google.maps.LatLng(49.3354466, 8.5324112),
    new window.google.maps.LatLng(49.3700752, 8.5283129),
    new window.google.maps.LatLng(49.2676069, 8.6175783),
    new window.google.maps.LatLng(49.4117174, 8.7091559),
    new window.google.maps.LatLng(49.42784049999999, 8.6856906),
    new window.google.maps.LatLng(49.403916, 8.6462653),
    new window.google.maps.LatLng(49.30302160000001, 8.6427023),
    new window.google.maps.LatLng(49.30297669999999, 8.6417319),
    new window.google.maps.LatLng(49.4743669, 8.6620046),
    new window.google.maps.LatLng(49.475533, 8.6632465),
    new window.google.maps.LatLng(49.3262684, 8.6859808),
    new window.google.maps.LatLng(49.4336201, 8.8091208),
    new window.google.maps.LatLng(49.3613317, 8.8003959),
    new window.google.maps.LatLng(49.46401789999999, 8.9853729),
    new window.google.maps.LatLng(49.5504985, 8.6705449),
    new window.google.maps.LatLng(49.52725, 8.66636),
    new window.google.maps.LatLng(49.51486999999999, 8.65732),
    new window.google.maps.LatLng(49.5973558, 8.7372731),
    new window.google.maps.LatLng(49.5329189, 8.7267428),
    new window.google.maps.LatLng(48.7740849, 9.1721011),
    new window.google.maps.LatLng(48.7668856, 9.1786785),
    new window.google.maps.LatLng(48.8112816, 9.163837),
    new window.google.maps.LatLng(48.6784196, 9.2184914),
    new window.google.maps.LatLng(48.66132229999999, 9.1199421),
    new window.google.maps.LatLng(48.9632014, 9.4312147),
    new window.google.maps.LatLng(48.9376455, 9.5001358),
    new window.google.maps.LatLng(48.9075098, 9.467465),
    new window.google.maps.LatLng(48.4882453, 8.5651565),
    new window.google.maps.LatLng(48.5515866, 8.7242828),
    new window.google.maps.LatLng(48.4692799, 8.5000972),
    new window.google.maps.LatLng(48.4697869, 8.5000273),
    new window.google.maps.LatLng(48.1202734, 9.0785519),
    new window.google.maps.LatLng(48.5294519, 9.3451793),
    new window.google.maps.LatLng(48.49770900000001, 9.198174),
    new window.google.maps.LatLng(48.49006929999999, 9.2166715),
    new window.google.maps.LatLng(48.4331238, 9.1497733),
    new window.google.maps.LatLng(48.3089927, 9.2452295),
    new window.google.maps.LatLng(48.3892175, 9.1805916),
    new window.google.maps.LatLng(48.55049, 9.47082),
    new window.google.maps.LatLng(48.8070793, 9.5417813),
    new window.google.maps.LatLng(48.8035451, 9.5332178),
    new window.google.maps.LatLng(48.8064512, 9.5272907),
    new window.google.maps.LatLng(48.87488, 9.6352),
    new window.google.maps.LatLng(48.7401008, 9.3018803),
    new window.google.maps.LatLng(48.68478, 9.27816),
    new window.google.maps.LatLng(49.1492874, 9.2862174),
    new window.google.maps.LatLng(49.1014087, 9.2128616),
    new window.google.maps.LatLng(49.179363, 9.3222446),
    new window.google.maps.LatLng(48.9476701, 9.1458979),
    new window.google.maps.LatLng(48.96018, 9.1345),
    new window.google.maps.LatLng(48.9607946, 9.1311584),
    new window.google.maps.LatLng(48.9598571, 9.1289916),
    new window.google.maps.LatLng(48.9486462, 9.1362477),
    new window.google.maps.LatLng(49.07862, 9.06525),
    new window.google.maps.LatLng(49.0762, 9.14924),
    new window.google.maps.LatLng(49.2778329, 9.6883054),
    new window.google.maps.LatLng(49.3543929, 9.147422),
    new window.google.maps.LatLng(48.8773336, 8.6770011),
    new window.google.maps.LatLng(48.7258581, 8.7956537),
    new window.google.maps.LatLng(49.0013395, 8.4083715),
    new window.google.maps.LatLng(49.0111021, 8.3614099),
    new window.google.maps.LatLng(49.01139, 8.35938),
    new window.google.maps.LatLng(48.99699039999999, 8.3531593),
    new window.google.maps.LatLng(48.9472984, 8.4083076),
    new window.google.maps.LatLng(48.9654834, 8.3012137),
    new window.google.maps.LatLng(48.970391, 8.2691101),
    new window.google.maps.LatLng(49.0040963, 8.5271505),
    new window.google.maps.LatLng(48.9855994, 8.5449336),
    new window.google.maps.LatLng(48.80047279999999, 8.4402126),
    new window.google.maps.LatLng(49.1301658, 8.4176175),
    new window.google.maps.LatLng(49.1256066, 8.4098065),
    new window.google.maps.LatLng(48.7741372, 8.2230248),
    new window.google.maps.LatLng(48.7970256, 8.1109904),
    new window.google.maps.LatLng(49.1447086, 8.6649895),
    new window.google.maps.LatLng(49.2176509, 8.3757173),
    new window.google.maps.LatLng(49.093681, 8.27732),
    new window.google.maps.LatLng(49.1489346, 8.3140065),
    new window.google.maps.LatLng(49.2407518, 8.2164065),
    new window.google.maps.LatLng(48.68204, 8.19171),
    new window.google.maps.LatLng(48.62797, 8.07179),
    new window.google.maps.LatLng(48.081883, 8.4995733),
    new window.google.maps.LatLng(48.06751, 8.53677),
    new window.google.maps.LatLng(47.93772509999999, 8.7318364),
    new window.google.maps.LatLng(47.6585596, 9.1711323),
    new window.google.maps.LatLng(48.16388, 8.630229),
    new window.google.maps.LatLng(48.16245439999999, 8.6318264),
    new window.google.maps.LatLng(48.00672669999999, 7.8631607),
    new window.google.maps.LatLng(47.9193033, 7.6978895),
    new window.google.maps.LatLng(48.0674092, 7.8847542),
    new window.google.maps.LatLng(48.17500870000001, 8.071759),
    new window.google.maps.LatLng(48.1739, 8.0709604),
    new window.google.maps.LatLng(48.0318417, 7.7627742),
    new window.google.maps.LatLng(48.0449354, 7.6469367),
    new window.google.maps.LatLng(47.93543, 7.62725),
    new window.google.maps.LatLng(48.162316, 7.7962466),
    new window.google.maps.LatLng(47.5904785, 7.5922842),
    new window.google.maps.LatLng(47.5592354, 7.7848152),
    new window.google.maps.LatLng(48.1345276, 11.5689565),
    new window.google.maps.LatLng(48.13630000000001, 11.57906),
    new window.google.maps.LatLng(48.1376027, 11.5348155),
    new window.google.maps.LatLng(48.1373375, 11.5230166),
    new window.google.maps.LatLng(48.1298886, 11.4881276),
    new window.google.maps.LatLng(48.15486430000001, 11.5669313),
    new window.google.maps.LatLng(48.161964, 11.586499),
    new window.google.maps.LatLng(48.19205880000001, 11.4595614),
    new window.google.maps.LatLng(48.1285521, 11.5992379),
    new window.google.maps.LatLng(48.1401489, 11.6017605),
    new window.google.maps.LatLng(48.1727904, 11.3532851),
    new window.google.maps.LatLng(47.9959589, 11.1747471),
    new window.google.maps.LatLng(48.03473, 11.13831),
    new window.google.maps.LatLng(47.8391287, 11.1430109),
    new window.google.maps.LatLng(47.7745294, 11.1438977),
    new window.google.maps.LatLng(47.4933741, 11.0895878),
    new window.google.maps.LatLng(47.53002000000001, 11.11181),
    new window.google.maps.LatLng(47.8560232, 12.3434073),
    new window.google.maps.LatLng(47.7784944, 12.4491072),
    new window.google.maps.LatLng(47.8148312, 12.3764297),
    new window.google.maps.LatLng(47.7627823, 12.6459013),
    new window.google.maps.LatLng(47.7077405, 11.4131999),
    new window.google.maps.LatLng(48.541318, 12.1659178),
    new window.google.maps.LatLng(48.5298962, 12.1473775),
    new window.google.maps.LatLng(48.643049, 11.7872194),
    new window.google.maps.LatLng(48.520815, 11.9958244),
    new window.google.maps.LatLng(48.58996810000001, 12.0278247),
    new window.google.maps.LatLng(48.4493661, 12.0343802),
    new window.google.maps.LatLng(48.2767413, 12.1515951),
    new window.google.maps.LatLng(48.7653152, 11.4233067),
    new window.google.maps.LatLng(48.50133, 11.45448),
    new window.google.maps.LatLng(48.4355503, 11.8465408),
    new window.google.maps.LatLng(48.3064121, 11.8886143),
    new window.google.maps.LatLng(48.0777644, 11.969744),
    new window.google.maps.LatLng(48.1712225, 11.8058355),
    new window.google.maps.LatLng(48.2291469, 11.6726303),
    new window.google.maps.LatLng(48.2576256, 11.5569564),
    new window.google.maps.LatLng(48.3603673, 10.898018),
    new window.google.maps.LatLng(48.3800861, 10.932329),
    new window.google.maps.LatLng(48.3077146, 10.9060309),
    new window.google.maps.LatLng(48.2469533, 10.3592802),
    new window.google.maps.LatLng(48.45551, 10.81998),
    new window.google.maps.LatLng(48.45963, 10.65719),
    new window.google.maps.LatLng(48.4912586, 11.1848872),
    new window.google.maps.LatLng(48.7416585, 11.1824781),
    new window.google.maps.LatLng(47.8225024, 10.874994),
    new window.google.maps.LatLng(48.051089, 10.4905768),
    new window.google.maps.LatLng(47.60221, 9.88781),
    new window.google.maps.LatLng(47.6037058, 9.8875021),
    new window.google.maps.LatLng(47.6029462, 9.8888814),
    new window.google.maps.LatLng(47.7816793, 9.613278),
    new window.google.maps.LatLng(47.6870487, 9.8339526),
    new window.google.maps.LatLng(48.0993418, 9.7902255),
    new window.google.maps.LatLng(47.7661746, 9.1702772),
    new window.google.maps.LatLng(47.7539685, 9.1910854),
    new window.google.maps.LatLng(47.8096463, 9.1683207),
    new window.google.maps.LatLng(48.41541549999999, 9.9209001),
    new window.google.maps.LatLng(48.4894078, 9.6867613),
    new window.google.maps.LatLng(48.4583545, 10.2800151),
    new window.google.maps.LatLng(48.4070352, 10.2718929),
    new window.google.maps.LatLng(48.61100219999999, 10.5672794),
    new window.google.maps.LatLng(48.6789467, 10.1506517),
    new window.google.maps.LatLng(49.3256937, 11.0225744),
    new window.google.maps.LatLng(49.3236895, 11.0526108),
    new window.google.maps.LatLng(49.5594149, 11.3407818),
    new window.google.maps.LatLng(49.7166287, 11.072936),
    new window.google.maps.LatLng(49.7169928, 11.0598741),
    new window.google.maps.LatLng(49.7248451, 11.0498025),
    new window.google.maps.LatLng(49.7049015, 10.8056208),
    new window.google.maps.LatLng(49.30367, 10.57987),
    new window.google.maps.LatLng(49.3760955, 10.1849205),
    new window.google.maps.LatLng(49.2876751, 10.7863557),
    new window.google.maps.LatLng(49.2500814, 10.8313717),
    new window.google.maps.LatLng(49.44797459999999, 11.8472454),
    new window.google.maps.LatLng(49.44630739999999, 11.8473558),
    new window.google.maps.LatLng(49.4329276, 11.88791),
    new window.google.maps.LatLng(49.67435200000001, 12.1489451),
    new window.google.maps.LatLng(49.79901539999999, 12.1580067),
    new window.google.maps.LatLng(49.7527397, 11.8291869),
    new window.google.maps.LatLng(49.590778, 12.410051),
    new window.google.maps.LatLng(49.21936239999999, 12.664883),
    new window.google.maps.LatLng(49.2017977, 12.6601388),
    new window.google.maps.LatLng(49.19338, 12.51778),
    new window.google.maps.LatLng(48.8080169, 13.5478184),
    new window.google.maps.LatLng(48.87553519999999, 12.5763988),
    new window.google.maps.LatLng(48.63002340000001, 13.1914103),
    new window.google.maps.LatLng(48.6719333, 13.2897511),
    new window.google.maps.LatLng(50.3291476, 11.7106462),
    new window.google.maps.LatLng(50.3664424, 11.6405753),
    new window.google.maps.LatLng(49.8705861, 11.8905836),
    new window.google.maps.LatLng(49.8946603, 10.7255396),
    new window.google.maps.LatLng(49.76486999999999, 9.9484101),
    new window.google.maps.LatLng(49.7934504, 10.3414959),
    new window.google.maps.LatLng(49.7862781, 10.3416741),
    new window.google.maps.LatLng(49.8175214, 10.3516306),
    new window.google.maps.LatLng(50.043792, 10.2324233),
    new window.google.maps.LatLng(49.985136, 10.676124),
    new window.google.maps.LatLng(50.0006792, 10.1979295),
    new window.google.maps.LatLng(49.9981914, 10.244999),
    new window.google.maps.LatLng(49.99485, 9.57537),
    new window.google.maps.LatLng(49.67028819999999, 9.5175831),
    new window.google.maps.LatLng(49.4930289, 9.7741045),
    new window.google.maps.LatLng(49.48936, 9.78227),
    new window.google.maps.LatLng(50.97496109999999, 11.0194914),
    new window.google.maps.LatLng(50.9965352, 11.0266643),
    new window.google.maps.LatLng(51.01744, 11.022115),
    new window.google.maps.LatLng(51.0217134, 10.9932804),
    new window.google.maps.LatLng(50.97600610000001, 11.1057169),
    new window.google.maps.LatLng(50.83477, 10.94764),
    new window.google.maps.LatLng(50.8342993, 10.9497975),
    new window.google.maps.LatLng(50.981808, 11.3250323),
    new window.google.maps.LatLng(51.0254735, 11.513977),
    new window.google.maps.LatLng(51.1585945, 11.128301),
    new window.google.maps.LatLng(51.5145581, 10.8003408),
    new window.google.maps.LatLng(50.99495, 10.3104),
    new window.google.maps.LatLng(50.8614085, 10.5740187),
    new window.google.maps.LatLng(50.7924354, 10.6102649),
    new window.google.maps.LatLng(51.2144545, 10.4598477),
    new window.google.maps.LatLng(0.0, 0.0),
    new window.google.maps.LatLng(0.0, 0.0),
    new window.google.maps.LatLng(50.11434269999999, 8.6743804),
    new window.google.maps.LatLng(51.6612328, 6.4614965),
    new window.google.maps.LatLng(50.86615, 9.70545),
    new window.google.maps.LatLng(50.14957580000001, 8.6816849),
    new window.google.maps.LatLng(53.15846939999999, 12.171308),
    new window.google.maps.LatLng(49.4448228, 11.8684161),
    new window.google.maps.LatLng(50.1557346, 7.1801621),
    new window.google.maps.LatLng(49.4076313, 8.6845362),
    new window.google.maps.LatLng(52.03788590000001, 8.9023059),
    new window.google.maps.LatLng(52.5422483, 13.3701997),
    new window.google.maps.LatLng(51.4998263, 6.549573),
    new window.google.maps.LatLng(50.5746198, 6.4953894),
    new window.google.maps.LatLng(48.8602472, 8.237623),
    new window.google.maps.LatLng(51.7743583, 9.3815251),
    new window.google.maps.LatLng(52.373995, 9.738591),
    new window.google.maps.LatLng(50.0577585, 8.3434416),
    new window.google.maps.LatLng(49.9669959, 8.3894499),
    new window.google.maps.LatLng(52.5015421, 13.324765),
    new window.google.maps.LatLng(50.8350491, 12.9550319),
    new window.google.maps.LatLng(50.37190349999999, 8.0101866),
    new window.google.maps.LatLng(51.0082458, 11.6831),
    new window.google.maps.LatLng(51.66368689999999, 6.9648961),
    new window.google.maps.LatLng(51.4649826, 7.5247967),
    new window.google.maps.LatLng(51.13445, 9.2771678),
    new window.google.maps.LatLng(50.2023836, 9.1769403),
    new window.google.maps.LatLng(50.1314271, 8.9118259),
    new window.google.maps.LatLng(50.1330707, 8.9095415),
    new window.google.maps.LatLng(52.33756, 9.76978),
    new window.google.maps.LatLng(49.7016298, 11.0443042),
    new window.google.maps.LatLng(50.01868169999999, 8.3726154),
    new window.google.maps.LatLng(49.4689151, 8.5591938),
    new window.google.maps.LatLng(51.5042238, 10.7984013),
    new window.google.maps.LatLng(50.9123538, 9.1885964),
    new window.google.maps.LatLng(51.9126317, 14.1231924),
    new window.google.maps.LatLng(49.29088369999999, 8.6970416),
    new window.google.maps.LatLng(50.0294923, 7.1592908),
    new window.google.maps.LatLng(52.605838, 8.372946),
    new window.google.maps.LatLng(49.0010707, 9.1086638),
    new window.google.maps.LatLng(51.5338918, 7.6755756),
    new window.google.maps.LatLng(54.66647409999999, 9.3942692),
    new window.google.maps.LatLng(51.2364135, 6.7809662),
    new window.google.maps.LatLng(49.7054027, 8.6178812),
    new window.google.maps.LatLng(48.4890161, 9.2170539),
    new window.google.maps.LatLng(49.6992376, 6.5745315),
    new window.google.maps.LatLng(53.1247288, 8.0912453),
    new window.google.maps.LatLng(50.26612, 8.69888),
    new window.google.maps.LatLng(48.3650889, 10.8880011),
    new window.google.maps.LatLng(51.3626458, 9.4558153),
    new window.google.maps.LatLng(50.1377558, 7.1326017),
    new window.google.maps.LatLng(51.93608949999999, 8.8742545),
    new window.google.maps.LatLng(52.9875957, 8.8168825),
    new window.google.maps.LatLng(49.4745884, 8.6585911),
    new window.google.maps.LatLng(53.30968, 9.9578201),
    new window.google.maps.LatLng(0.0, 0.0),
    new window.google.maps.LatLng(53.3353742, 13.439111),
    new window.google.maps.LatLng(50.7226563, 7.0434982),
    new window.google.maps.LatLng(50.0789045, 8.2438269),
    new window.google.maps.LatLng(50.9253328, 6.0939803),
    new window.google.maps.LatLng(50.8177203, 8.1080386),
    new window.google.maps.LatLng(50.81503259999999, 7.1578831),
    new window.google.maps.LatLng(49.0437102, 9.1844389),
    new window.google.maps.LatLng(53.89853489999999, 12.8516072),
    new window.google.maps.LatLng(48.9265103, 9.4796182),
    new window.google.maps.LatLng(48.1887437, 11.4677219),
    new window.google.maps.LatLng(52.21021, 7.0437299),
    new window.google.maps.LatLng(48.6812315, 9.0061612),
    new window.google.maps.LatLng(50.0573662, 8.340208),
    new window.google.maps.LatLng(52.4984735, 13.3508347),
    new window.google.maps.LatLng(51.9392435, 13.9228219),
    new window.google.maps.LatLng(48.1624683, 11.5872733),
    new window.google.maps.LatLng(50.4332518, 7.6720803),
    new window.google.maps.LatLng(51.1486771, 14.9557465),
    new window.google.maps.LatLng(50.8491803, 12.4645097),
    new window.google.maps.LatLng(52.4904658, 13.4093663),
    new window.google.maps.LatLng(52.6685057, 13.2820572),
    new window.google.maps.LatLng(47.7623422, 12.6451192),
    new window.google.maps.LatLng(51.47308760000001, 6.9526587),
    new window.google.maps.LatLng(49.5159544, 8.5224736),
    new window.google.maps.LatLng(49.79975779999999, 10.2300436),
    new window.google.maps.LatLng(52.665763, 13.2961567),
    new window.google.maps.LatLng(51.59160499999999, 7.338964),
    new window.google.maps.LatLng(50.0760976, 8.2400017),
    new window.google.maps.LatLng(50.07915209999999, 8.2437993),
    new window.google.maps.LatLng(47.85668, 11.48747),
    new window.google.maps.LatLng(50.9129752, 8.5236386),
    new window.google.maps.LatLng(48.1136109, 11.4788246),
    new window.google.maps.LatLng(51.09796710000001, 6.1557074),
    new window.google.maps.LatLng(51.3450213, 12.3920368),
    new window.google.maps.LatLng(48.1784907, 11.2554311),
    new window.google.maps.LatLng(48.8095234, 9.1596498),
    new window.google.maps.LatLng(51.9451849, 7.167249),
    new window.google.maps.LatLng(51.5776881, 6.9614247),
    new window.google.maps.LatLng(50.9662545, 6.9143597),
    new window.google.maps.LatLng(48.1506888, 7.7506037),
    new window.google.maps.LatLng(50.9946897, 13.8012526),
    new window.google.maps.LatLng(51.8070074, 10.9393847),
    new window.google.maps.LatLng(48.1651731, 11.6442421),
    new window.google.maps.LatLng(50.68871679999999, 7.3960882),
    new window.google.maps.LatLng(50.07915209999999, 8.2437993),
    new window.google.maps.LatLng(50.7343778, 12.3787377),
    new window.google.maps.LatLng(49.5781143, 7.6992422),
    new window.google.maps.LatLng(50.0603401, 8.1116367),
    new window.google.maps.LatLng(47.759992, 8.8421965),
    new window.google.maps.LatLng(51.0693941, 13.7492306),
    new window.google.maps.LatLng(53.10278959999999, 7.6771846),
    new window.google.maps.LatLng(51.084431, 13.7094564),
  ];

  return (
    <div>
    <GoogleMap
      mapContainerClassName="map-container"
      center={center}
      zoom={7}
      mapTypeId='satellite'
    >
      <HeatmapLayerF data={heatmapData} />
    </GoogleMap>
    </div>
  );
}

export default GoogleHeatMap;
