import { useEffect, useState } from "react";
import { Decoder } from "@nuintun/qrcode";

function SingleQRCode(props) {
  const [filePreviewText, setFilePreviewText] = useState();
  const filePreview = props.filePreview;
  useEffect(() => {
    async function fetchData() {
      setFilePreviewText(await getQRCodeAsText(props.filePreview));
    }
    fetchData();
  }, [filePreview]);

  const getQRCodeAsText = async (filePreview) => {
    const qrcode = new Decoder();
    if (!filePreview) {
      return;
    }

    return await qrcode
      .scan(filePreview)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <img src={props.filePreview} style={{ width: "200px" }} alt="preview" />
        <div style={{ margin: "auto", width: "50%" }}>
          <textarea rows={3} className="form-control" value={filePreviewText} />
        </div>
        <div style={{ margin: "auto" }}>
          <button
            className="form-control "
            onClick={() => {
              navigator.clipboard.writeText(filePreviewText);
            }}
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
}
export default SingleQRCode;
