import "../App.css";
import "react-pro-sidebar/dist/css/styles.css";
import * as process from "process";
import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";

window["process"] = process;
window["global"] = window;
window["Buffer"] = window;

const JSONExample = {
  glossary: {
    title: "example glossary",
    GlossDiv: {
      title: "S",
      GlossList: {
        GlossEntry: {
          ID: "SGML",
          SortAs: "SGML",
          GlossTerm: "Standard Generalized Markup Language",
          Acronym: "SGML",
          Abbrev: "ISO 8879:1986",
          GlossDef: {
            para: "A meta-markup language, used to create markup languages such as DocBook.",
            GlossSeeAlso: ["GML", "XML"],
          },
          GlossSee: "markup",
        },
      },
    },
  },
};

const {
  quicktype,
  InputData,
  jsonInputForTargetLanguage,
} = require("quicktype-core");
var Buffer = require("buffer/").Buffer;
function JSONtoTS() {
  const [lines, setLines] = useState([]);
  const [jsonObject, setJsonObject] = useState();
  const pretty = JSON.stringify(jsonObject, undefined, 2);
  const typescript = lines.join("\n");
  /*   const TSpretty = (lines, undefined, 2); */

  useEffect(
    () => {
      quicktypeJSON("typescript", "Root", JSON.stringify(jsonObject)).then(
        (typescriptResult) => {
          setLines(typescriptResult.lines);
          console.log(typescriptResult.lines.join("\n"));
        }
      );
      async function quicktypeJSON(targetLanguage, typeName, jsonString) {
        const jsonInput = jsonInputForTargetLanguage(targetLanguage);

        // We could add multiple samples for the same desired
        // type, or many sources for other types. Here we're
        // just making one type from one piece of sample JSON.
        await jsonInput.addSource({
          name: typeName,
          samples: [jsonString],
        });

        const inputData = new InputData();
        inputData.addInput(jsonInput);

        return await quicktype({
          inputData,
          lang: targetLanguage,
          rendererOptions: { "just-types": "true" },
        });
      }
    },
    [jsonObject] //important
  );
  return (
    <div className="mx-auto px-4 mt-12">
      <h3 style={{ paddingLeft: "5px" }}>{"JSON to Typescript"}</h3>
      <p style={{ paddingLeft: "5px" }}>
        Generate typescript from JSON samples
      </p>
      <div className="grid grid-cols-2">
        <div className="p-2 col-span-2 lg:col-span-1 mb-10">
          <div className="flex flex-wrap items-stretch w-full mb-4 relative">
            {/*String Input for Encrypt*/}

            <div className="input-group">
              <div>
                <h2>Input JSON</h2>
              </div>
              <div style={{ paddingLeft: 10 }} className="input-group-prepend">
                <button
                  className="btn btn-light "
                  onClick={async () => {
                    let clipboard = navigator.clipboard
                      .readText()
                      .then((clipBoardText) => {
                        try {
                          console.log(clipBoardText);
                          let clipboardObject = JSON.parse(clipBoardText);
                          console.log(JSON.stringify(JSONtoTS));
                          setJsonObject(clipboardObject);
                        } catch {
                          alert("Not JSON");
                        }
                      });
                  }}
                >
                  {" "}
                  Paste{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-stretch w-full mb-4 relative">
            <TextareaAutosize
              style={{ width: "100%" }}
              className="form-control"
              value={pretty}
            ></TextareaAutosize>
          </div>
        </div>
        <div className="p-2 col-span-2 lg:col-span-1">
          <div className="flex flex-wrap items-stretch w-full mb-4 relative">
            <div className="input-group">
              <div>
                <h2>Typescript Output</h2>
              </div>
              <div style={{ paddingLeft: 10 }} className="input-group-prepend">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(lines.join("\n"));
                  }}
                  className="btn btn-light"
                >
                  {" "}
                  Copy{" "}
                </button>
              </div>
            </div>
          </div>
          {/*Show Encrypt*/}
          <div className="flex flex-wrap items-stretch w-full mb-4 relative">
            <TextareaAutosize
              style={{ width: "100%" }}
              className="form-control"
              value={lines.join("\n")}
            ></TextareaAutosize>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JSONtoTS;
